import React from 'react';

import { FaEllipsisH } from 'react-icons/fa';
import { BsGlobeEuropeAfrica } from 'react-icons/bs';

import './link-preview-cards-style.css';


export default function FacebookCard({ FacebookPreview }) {


  return (
    <div className={'social-media-card-container'}>
      <div className={'social-media-cards-style'}>
        <div className={'social-media-card-body'}>
          <div className={'social-media-card-header facebook'}>
            <div className={'card-header-icon'}>
              <img src={'/fb-default.png'} alt={'facebook-icon'} />
            </div>
            <div style={{ marginLeft: '6px' }}>
              <p className={'card-header-title facebook'}>OpenGraph.io</p>
              <span style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <p className={'sponsored-facebook'}>Sponsored</p>
                                <div className={'facebook-circle'}></div>
                                <BsGlobeEuropeAfrica size={10} style={{ margin: 'auto 2px', color: 'rgba(96, 103, 112, 1)' }}/>
                            </span>
            </div>
            <div style={{ marginLeft: 'auto'}}>
              <FaEllipsisH style={{ color: '#606770FF'}}/>
            </div>
          </div>
          <div className={'card-facebook-comment'}>
            <p>Check this out!</p>
            <p>#tagOne #tagTwo #tagThree #tagOne #tagTwo #tagThree #tagThree</p>
            <p>https://www.opengraph.io</p>
          </div>
        </div>
        {FacebookPreview.props.results ? FacebookPreview : <div className={'social-media-card-image'}>
          <img src={'/facebook-link-preview-example.png'} alt={'Facebook Link Preview Example'} />
        </div>}
      </div>
    </div >

  )
}
