import React, {Component} from 'react';
import {Alert, Modal, ModalBody} from 'reactstrap';
import {Elements} from '@stripe/react-stripe-js';
import StripePaymentForm from './StripePaymentForm';
import {connect} from 'react-redux';
import {clearStripeError} from '../../../redux/actions/plans';
// import * as Stripe from '../../../../node_modules/\@stripe/stripe-js/dist/index.js';
import * as Stripe from '\@stripe/stripe-js/dist/stripe.js';

const stripeKey = process.env.REACT_APP_STRIPE_ENV === 'test'
    ? 'pk_test_7nyKxLOrQClPS161Ix93dyzt'
    : 'pk_live_30x8uLqquZQVmiazaPvQfKJc'
console.log('stripeKey', stripeKey);
const stripePromise = Stripe.loadStripe(stripeKey);

const StripeChangeCardModal = (props) => {
    const {visible, stripeError, toggle, clearStripeError} = props

    const stripeOptions = {
      mode: 'subscription',
      amount: 0,
      currency: 'usd',
    };
    return (
      <Elements stripe={stripePromise} options={stripeOptions}>
        <Modal isOpen={visible} toggle={toggle} className={'modal-dialog--primary paymentModal '}>
          <div className={'modal__header centerText bottomBorder'}>
            <button className="lnr lnr-cross modal__close-btn" onClick={toggle} />
            {stripeError ? (
                    <Alert color={'danger'} className="alert--bordered" isOpen={!!stripeError} toggle={() => clearStripeError()}>
                      <div className={'padding5'}>
                        <p>{stripeError}</p>
                      </div>
                    </Alert>) : (<h4 className={'bold-text modal__title'}>
              Change Card
            </h4>)
            }
            <br />
          </div>
          <ModalBody>
            <div className={'modal__body leftText borderRadius5'}>
              <StripePaymentForm
                  justChangeCard={true}
                  buttonText={'Change Card'}
                  isOpen={!visible}
              />
            </div>
          </ModalBody>
        </Modal>
      </Elements>
    )
}

const mapStateToProps = (state) => ({
  stripeError: state.plans.stripeError
})

const mapDispatchToProps = (dispatch) => ({
  clearStripeError: (payload) => dispatch(clearStripeError(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(StripeChangeCardModal)
