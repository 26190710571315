import React from 'react';


import OpengraphReactComponent from 'opengraph-react';

const ReactPreview = ({ results, loading, newResults, cardComponent }) => {
  console.log('results', results);
  console.log('newResults', newResults);
  return (

    <div className="react_component_preview-cards-container">
      <div>
        <OpengraphReactComponent dontMakeCall={true} results={results} newResults={newResults} component={cardComponent}/>
      </div>
    </div>
  );
}

export default ReactPreview;
