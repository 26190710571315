import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from '../App/MainWrapper';
import LogIn from '../LogIn/index';
import Dashboard from '../Dashboard/index';
import ApiDetails from '../Apis/ApiDetails';
import ApiEdit from '../Apis/ApiEdit';
import Profile from '../Profile/Profile';
import DebugTool from '../Debug/DebugTool';
import LinkPreview from '../LinkPreview';
import AdminUsersDashboard from '../AdminUsersDashboard/AdminUsersDashboard.js';
import AdminApiDetails from '../AdminUsersDashboard/components/AdminApiDetails';
import Support from '../Layout/sidebar/SupportModal';
import ApiList from '../Admin/Apis';
import AdminApiEdit from '../Admin/Apis/Details';
import PlansAndPricing from '../Billing'
import Error from './error';
import OrganizationUsersDashboard from '../OrganizationUsersDashboard';
import AdminWrapper from '../App/AdminWrapper';

const Apis = () => (
  <Switch>
    <Route path="/apis/debug/:name" component={DebugTool} />
    <Route path="/apis/billing/:name" component={PlansAndPricing} />
    <Route path="/apis/:name" component={ApiDetails} />
  </Switch>
);

const AdminRoutes = () => (
  <AdminWrapper>
    <main>
    <Switch>
    {/*<Route path="/" component={AdminUsersDashboard}/>*/}
      <Route path="/admin/apis/edit/:name" component={ApiEdit}/>
      <Route path="/admin/users/:orgId/dashboard" component={AdminApiDetails}/>
      <Route path="/admin/users" component={AdminUsersDashboard} />
      <Route exact path="/admin/apis" component={ApiList} />
      <Route exact path="/admin/apis/new" component={AdminApiEdit} />
      <Route path="/admin/apis/:apiId" component={AdminApiEdit} />
    </Switch>
    </main>
  </AdminWrapper>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/profile" component={Profile} />
      <Route path="/apis" component={Apis} />
      <Route path="/api-playground" component={DebugTool}/>
      <Route path="/link-preview" component={LinkPreview}/>
      <Route path="/debug" component={DebugTool}/>
      <Route path="/admin" component={AdminRoutes}/>
      <Route path="/support" component={Support}/>
      <Route path="/organization" component={OrganizationUsersDashboard}/>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/" component={LogIn} />
        <Route exact path="/error" component={Error} />
        <Route exact path="/login" component={LogIn} />
        <Route exact path="/register" component={LogIn} />
        <Route exact path="/resetpassword" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
