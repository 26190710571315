import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import thunk from 'redux-thunk';
import { createTracker } from 'redux-segment';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  sidebarReducer,
  themeReducer,
  users,
  apis,
  plans,
  apiKeys,
  planPeriods,
  apiLogs,
  whiteLabel,
  admin,
  stripeModal,
  debuggerForm,
  organization,
  linkPreview
} from '../../redux/reducers/index';
import { TrackJS } from 'trackjs';

const reducer = combineReducers({
  // Reducers
  form: reduxFormReducer,
  theme: themeReducer,
  sidebar: sidebarReducer,
  apis,
  users,
  plans,
  apiKeys,
  planPeriods,
  apiLogs,
  whiteLabel,
  admin,
  stripeModal,
  organization,
  debuggerForm,
  linkPreview
});

const TrackJSLogger = store => next => action => {
  // Middleware to log actions with TrackJS
  try {
    TrackJS.console.log(action);
    return next(action);
  } catch (err) {
    console.warn(store.getState());
    TrackJS.track(err);
  }
};

const tracker = createTracker();

// Only add redux-logger in development mode
const middlewares = [thunk, tracker, TrackJSLogger];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(createLogger());
}

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));

export default store;
