import React, {useState} from 'react'
import './mobile-navbar.scss'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {logoutUser} from '../../../redux/actions/users'
import SupportModal from '../sidebar/SupportModal'
import {FcMenu} from 'react-icons/fc'
import {CgProfile} from 'react-icons/cg';


const MobileNavigation = (props) => {
    const [mobileOpen, setMobileOpen] = useState(false)
    const [supportModalOpen, setSupportModalOpen] = useState(false)

    const user = props.user

    const handleOpen = () => {
        setMobileOpen(!mobileOpen)
    }

    const toggleSupportModal = () => {
        setSupportModalOpen(!supportModalOpen)
    };


    return (
        <nav className="mobile-navigation fixed-top">
            <SupportModal visible={supportModalOpen}  toggle={()  => toggleSupportModal()} apiId={props.plan.apiId}/>
            <div className={'mobile-navigation--container container'}>
                <Link className="mobile-navigation--logo" to="/">OpenGraph<span style={{color: '#2CBD6B'}}>.</span>io</Link>
                <div className={`mobile-navigation--item_container ${mobileOpen ? 'open' : ''}`}>
                    {user ? (<ul className="">
                        <li className={'mobile-navigation-item'}>
                            <Link to="/apis/Opengraphio">Dashboard</Link>
                        </li>
                        <li className="mobile-navigation-item">
                            <Link className="" to="/apis/billing/Opengraphio">Billing</Link>
                        </li>
                        <li className="mobile-navigation-item">
                            <a target="_blank" href="https://www.opengraph.io/documentation">Documentation</a>
                        </li>
                        <li className="mobile-navigation-item">
                            <Link className="" to="/link-preview">Link Preview</Link>
                        </li>
                        <li className="mobile-navigation-item">
                            <Link className="" to="/api-playground">API Playground</Link>
                        </li>
                        <li className="mobile-navigation-item">
                            <Link className="" to="/organization">Manage Organization</Link>
                        </li>
                        <li className="mobile-navigation-item">
                            <Link className="" to="/profile">Profile</Link>
                        </li>
                        <li className="mobile-navigation-item">
                            <a className="mobile-navigation-logout" onClick={props.logout} href="/">Logout</a>
                        </li>
                        <li className="mobile-navigation-item">
                            <p className="mobile-navigation-logout" onClick={() => toggleSupportModal()}>Support</p>
                        </li>
                    </ul>) : (
                      <ul className="">
                          <li className={'mobile-navigation-item'}>
                              <Link to="/login">Login</Link>
                          </li>
                          <li className="mobile-navigation-item">
                              <Link className="" to="/register">Register</Link>
                          </li>
                      </ul>
                    )}
                </div>
                <FcMenu size={36} onClick={() => handleOpen()} />
            </div>
        </nav>
    )
}

const mapStateToProps = (state) => ({
    plan: state.plans.detailPlan,
    user: state.users.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation)
