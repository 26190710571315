import React from 'react';

import './link-preview-cards-style.css'

export default function XCard( { XPreview }) {
  return (
    <div className={'social-media-card-container'}>
      <div className={'social-media-cards-style'}>
        <div className={'social-media-card-body x'}>
          <div>
            <div className={'card-header-icon'}>
              <img src={'/x-profile-icon.png'} alt={'X | Twitter Link Preview'} />
            </div>
          </div>
          <div>
            <div className={'social-media-card-header x'}>
              <p className={''}>OpenGraph.io</p>
              <p className={''}>@opengraph-io</p>
              <p className={''}>now</p>
            </div>
            {XPreview.props.results ? XPreview : <img className={'social-media-card-image'} src={'/x-link-preview-example.png'}
                  alt={'X Link Preview Example'}/>}
            <div className={'social-media-card-comment x'}>
              <p>Check this out!</p>
              <p>Tending Mobile, App, and Interface Designs on...</p>
            </div>
          </div>
        </div>
      </div>
    </div >

  )
}
