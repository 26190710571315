import React from 'react'

import { FaEllipsisH } from 'react-icons/fa';
import { BsGlobeEuropeAfrica } from 'react-icons/bs';
import { IoIosSend } from 'react-icons/io';

import './link-preview-cards-style.css';


export default function LinkedInCard({ LinkedInPreview }) {
  return (
    <div className={'social-media-card-container'}>
      <div className={'social-media-cards-style'}>
        <div className={'social-media-card-body'}>
          <div className={'social-media-card-header linkedin'}>
            <div className={'card-header-icon linkedin'}>
              <img src={'/fb-default.png'} alt={'facebook-icon'} />
            </div>
            <div style={{ marginLeft: '6px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <p className={'card-header-title facebook'}>OpenGraph.io</p>
              <p className={'sponsored-facebook'}>100 followers</p>
              <span style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <p className={'sponsored-facebook'}>1d</p>
                                <div className={'facebook-circle'}></div>
                                <BsGlobeEuropeAfrica size={10} style={{ margin: 'auto 2px', color: 'rgba(96, 103, 112, 1)' }}/>
                            </span>
            </div>
            <div style={{ marginLeft: 'auto'}}>
              <FaEllipsisH style={{ color: '#606770FF'}}/>
            </div>
          </div>
          <div className={'card-facebook-comment'}>
            <p>Trending Mobile, App, and Interface Designs on our Website. It will truncate after about three lines of text or 220 characters. The truncation is odd because rather than an ellipsis, the “...see more” link covers it in a white box</p>
          </div>
        </div>
        {LinkedInPreview.props.results ? LinkedInPreview : <img className={'social-media-card-image'} src={'/linkedin-link-preview.png'}
              alt={'LinkedIn Link Preview Example'}/>}
        <div className={'social-media-card-footer linkedin'}>
          <div style={{ color: '#666666', justifyContent: 'space-between', margin: 'auto 5px'}}>
            <div>
              <img
                style={{ width: '50px'}}
                src={'/reply-icons.png'}
                alt={'reply icon'}
              />
              <p>Bob Loblaw and 2 others</p>
            </div>
            <div>
              <p>6 comments</p>
              <div className={'facebook-circle'}></div>
              <p>25 reposts</p>
            </div>
          </div>
          <hr style={{ width: '90%', margin: '0 auto'}}/>
          <div style={{ justifyContent: 'space-evenly', color: '#404028'}}>
                        <span>
                            <img src={'/thumbs-up.svg'} alt={'like icon'} height={20} width={20}/>
                            <p>Like</p>
                        </span>
            <span>
                            <img src={'/bubble.svg'} alt={'comment icon'} height={20} width={20}/>
                            <p>Comment</p>
                        </span>
            <span>
                            <img src={'/repost.svg'} alt={'repost icon'} height={20} width={20}/>
                            <p>Repost</p>
                        </span>
            <span>
                            <IoIosSend size={20} />
                            <p>Send</p>
                        </span>
          </div>

        </div>
      </div>
    </div >

  )
}
