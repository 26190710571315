import {
  EDIT_LINK_PREVIEW_META_DATA,
  EDIT_UPDATED_RESULTS, MAKE_LINK_PREVIEW_CALL, MAKE_LINK_PREVIEW_CALL_ERROR, MAKE_LINK_PREVIEW_CALL_SUCCESS,
  SET_ACTIVE_LINK_PREVIEW_TAB, TOGGLE_MARKETING_SIGN_UP_MODAL
} from '../actionTypes';

const initialState = {
  form: {
    url: '',
    title: '',
    description: '',
    image: ''
  },
  results: null,
  newResults: null,
  updatedResults: null,
  isSubmitting: false,
  error: null,
  success: null,
  loading: false,
  showCaptcha: false,
  showSignUpModal: false,
  metaTags: {
    title: '',
    description: '',
    image: '',
    url: '',
    type: ''
  },
  activeTab: 'facebook'
}

const linkPreview = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_LINK_PREVIEW_META_DATA:
      return {
        ...state,
        form: {
          ...state.form,
          [action.name]: action.value
        },
        metaTags: {
          ...state.metaTags,
          [action.name]: action.value
        }
      };
    case EDIT_UPDATED_RESULTS:
      return {
        ...state,
        results: {
          ...action.payload
        }
      };
    case SET_ACTIVE_LINK_PREVIEW_TAB:
      return {
        ...state,
        activeTab: action.tab
      };
      case MAKE_LINK_PREVIEW_CALL:
        return {
          ...state,
          loading: true
        };
      case MAKE_LINK_PREVIEW_CALL_SUCCESS:
        return {
          ...state,
          loading: false,
          results: action.results
        };
      case MAKE_LINK_PREVIEW_CALL_ERROR:
        return {
          ...state,
          loading: false,
          error: action.err
        };
      case TOGGLE_MARKETING_SIGN_UP_MODAL:
          return {
            ...state,
            showSignUpModal: action.open
          };
    default:
      return state;
  }
}

export default linkPreview;
