import {
  EDIT_LINK_PREVIEW_META_DATA,
  EDIT_UPDATED_RESULTS,
  MAKE_LINK_PREVIEW_CALL, MAKE_LINK_PREVIEW_CALL_ERROR, MAKE_LINK_PREVIEW_CALL_SUCCESS,
  SET_ACTIVE_LINK_PREVIEW_TAB,
  TOGGLE_MARKETING_SIGN_UP_MODAL
} from '../actionTypes';
import { TrackGoogleAnalyticsEvent } from '../../shared/utils/google-analytics';
import { opengraphApiBase } from '../../constants/config';


export const handleEditMetaData = (e) => {
  const { name, value } = e.target;

  return (dispatch, getState) => {
    dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name, value });
    const results = getState().linkPreview.results;

    if(results && results.hybridGraph) {
      dispatch({ type: EDIT_UPDATED_RESULTS, payload: { ...results, hybridGraph: {  ...results.hybridGraph, [name]: value } , openGraph: {  ...results.openGraph, [name]: value }, htmlInferred: {  ...results.htmlInferred, [name]: value } } });
    }


    if(name === 'image[url]') {
      dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value });
    }
  }
};

export const setLinkPreviewUrl = (url) => {
  return (dispatch) => {
    dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'url', value: url });
  }
}

export const setActiveTab = (tab) => {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_LINK_PREVIEW_TAB, tab });
  }
}

export const toggleMarketingSignUpModal = (open) => {
  return (dispatch) => {
    dispatch({ type: TOGGLE_MARKETING_SIGN_UP_MODAL, open });
  }
}


export function makeLinkPreviewCall(e){
  e.preventDefault()
  return (dispatch, getState) => {

    const previewUrl = getState().linkPreview.form.url;

    const apiKey = getState().plans.detailPlan.apiKeys[0].key;
    // let baseURL = process.env.NODE_ENV === 'production' ? 'https://opengraph.io/api/1.1/' : 'http://localhost:3778/opengraph/api/1.1/';
    let url = `${opengraphApiBase}link-preview/${encodeURIComponent(previewUrl)}?app_id=${apiKey}&cache_ok=false`;

    dispatch({type: MAKE_LINK_PREVIEW_CALL})
    //TODO: Create google Analytics Event
    // TrackGoogleAnalyticsEvent('debugToolCall','Usage Metrics', 'debugToolCall', url)

    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if(results.error) {
          if(results.error.code === 102) {
            dispatch({ type: TOGGLE_MARKETING_SIGN_UP_MODAL, open: true})
            dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.error.message })
            return
          }
          dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err: results.error.message })
        }
        console.log('HERE ARE THE RESULTS', results.hybridGraph);

        dispatch({ type: MAKE_LINK_PREVIEW_CALL_SUCCESS, results });

        const { image, title, description, url, type } = results.hybridGraph || {};

        if (image) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'image', value: image });
        }
        if (title) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'title', value: title });
        }
        if (description) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'description', value: description });
        }
        if(url) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'url', value: url });
        }
        if(type) {
          dispatch({ type: EDIT_LINK_PREVIEW_META_DATA, name: 'type', value: type });
        }
      })
      .catch((err) => {
        console.log('Error trying to preview a link.', err);
        dispatch({ type: MAKE_LINK_PREVIEW_CALL_ERROR, err })
      })
  }
}
