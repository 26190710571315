import React, { useEffect, useState} from 'react';
import {  Col, Container, Row } from 'reactstrap';
import LoginModal from '../../shared/components/Modals/LoginModal';
import { connect } from 'react-redux';
import { getLoggedInUser } from '../../redux/actions/users';
import { getApiByFriendlyName } from '../../redux/actions/apis';

import LinkPreviewTool from './components/LinkPreview/LinkPreviewTool';
import { getPlanByApiAndOrganization } from '../../redux/actions/plans';

const LinkPreview = (props) => {
  const [ displayLoginModal, setDisplayLoginModal ] = useState(true);
  const [alertDismissed, setAlertDismissed] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    props.getApiByFriendlyName('Opengraphio');
    props.getLoggedInUser();
  }, [])

  useEffect(() => {
    if (!props.user && !props.userLoading) {
      props.history.push('/login');
    }

    if (props.user && props.api.id && !props.plan.id && !props.planError) {
      props.getPlanByApiAndOrganization();
    }

  }, [props.api, props.user, props.plan.id, props.planError, props.apiError]);


  useEffect(() => {
    if(props.plan && props.plan.tierId && props.api && props.api.tiers){
      setCurrentPlan(props.api.tiers.find((tier) => tier.id === props.plan.tierId))
    }

  }, [props.plan, props.api])

  useEffect(() => {
    if(props.user) {
      setDisplayLoginModal(false);
    }
  }, [props.user]);

  const getPlan = () => {

    if(props.user && props.api && props.api.id && props.plan && !props.plan.id && !props.planError ){
      props.getPlanByApiAndOrganization();
    }
  }

  getPlan();

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12} xl={12}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '20px auto'}} className="form__debugger-group-header">
              <h2 className={'debug--h2'}>Link <span style={{ color: '#2CBD6B'}}>Preview</span> Tool</h2>
            <p>Preview and Tune your Social Media Links</p>
          </div>
        </Col>
        <Col md={12} xl={12}>
          <LinkPreviewTool />
        </Col>
      </Row>
      <Row>
      </Row>
    </Container>
    )
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  userState: state.users,
  plan: state.plans.detailPlan,
  planError: state.plans.error,
  api: state.apis.detailApi,
  formData: state.form.log_in_form,
  currentOrganization: state.organization.currentOrg
});

const mapDispatchToProps = (dispatch) => ({
  getLoggedInUser: () => dispatch(getLoggedInUser()),
  getPlanByApiAndOrganization: (() => dispatch(getPlanByApiAndOrganization())),
  getApiByFriendlyName: (payload) => dispatch(getApiByFriendlyName(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LinkPreview)
