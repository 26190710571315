export const USER_LOG_IN = 'USER_LOG_IN';
export const USER_LOG_IN_SUCCESS = 'USER_LOG_IN_SUCCESS';
export const USER_LOG_IN_FAIL = 'USER_LOG_IN_FAIL';

export const USER_GOOGLE_LOG_IN = 'USER_GOOGLE_LOG_IN';
export const USER_GOOGLE_LOG_IN_SUCCESS = 'USER_GOOGLE_LOG_IN_SUCCESS';
export const USER_GOOGLE_LOG_IN_FAIL = 'USER_GOOGLE_LOG_IN_FAIL';

export const USER_SIGN_UP = 'USER_SIGN_UP';
export const USER_SIGN_UP_SUCCESS = 'USER_SIGN_UP_SUCCESS';
export const USER_SIGN_UP_FAIL = 'USER_SIGN_UP_FAIL';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';

export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_FAIL = 'GET_LOGGED_IN_USER_FAIL';

export const CLEAR_USER_ALERT = 'CLEAR_USER_ALERT';

export const REQUEST_PASSWORD_EMAIL = 'REQUEST_PASSWORD_EMAIL';
export const REQUEST_PASSWORD_EMAIL_FAIL = 'REQUEST_PASSWORD_EMAIL_FAIL';
export const REQUEST_PASSWORD_EMAIL_SUCCESS = 'REQUEST_PASSWORD_EMAIL_SUCCESS';

export const REQUEST_VERIFICATION_EMAIL = 'REQUEST_VERIFICATION_EMAIL';
export const REQUEST_VERIFICATION_EMAIL_FAIL = 'REQUEST_VERIFICATION_EMAIL_FAIL';
export const REQUEST_VERIFICATION_EMAIL_SUCCESS = 'REQUEST_VERIFICATION_EMAIL_SUCCESS';

export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_FAIL = 'USER_LOGOUT_FAIL';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';

export const GET_APIS = 'GET_APIS';
export const GET_APIS_FAIL = 'GET_APIS_FAIL';
export const GET_APIS_SUCCESS = 'GET_APIS_SUCCESS';

export const GET_API_BY_FRIENDLY_NAME = 'GET_API_BY_FRIENDLY_NAME';
export const GET_API_BY_FRIENDLY_NAME_SUCCESS = 'GET_API_BY_FRIENDLY_NAME_SUCCESS';
export const GET_API_BY_FRIENDLY_NAME_FAIL = 'GET_API_BY_FRIENDLY_NAME_FAIL';

export const GET_PLAN_BY_API_AND_ORGANIZATION = 'GET_PLAN_BY_API_AND_ORGANIZATION';
export const GET_PLAN_BY_API_AND_ORGANIZATION_FAIL = 'GET_PLAN_BY_API_AND_ORGANIZATION_FAIL';
export const GET_PLAN_BY_API_AND_ORGANIZATION_SUCCESS = 'GET_PLAN_BY_API_AND_ORGANIZATION_SUCCESS';

export const CREATE_PLAN = 'CREATE_PLAN';
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
export const CREATE_PLAN_FAIL = 'CREATE_PLAN_FAIL';

export const CLEAR_PLAN_ALERT = 'CLEAR_PLAN_ALERT';

export const CLEAR_DETAIL_PLAN = 'CLEAR_DETAIL_PLAN';
export const CLEAR_DETAIL_API = 'CLEAR_DETAIL_API';

export const UPDATE_STRIPE_SOURCE_FOR_PLAN = 'UPDATE_STRIPE_SOURCE_FOR_PLAN';
export const UPDATE_STRIPE_SOURCE_FOR_PLAN_FAIL = 'UPDATE_STRIPE_SOURCE_FOR_PLAN_FAIL';
export const UPDATE_STRIPE_SOURCE_FOR_PLAN_SUCCESS = 'UPDATE_STRIPE_SOURCE_FOR_PLAN_SUCCESS';

export const SET_STRIPE_ERROR = 'SET_STRIPE_ERROR';

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_FAIL = 'UPDATE_PLAN_FAIL';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';

export const GET_API_KEYS_FOR_PLAN = 'GET_API_KEYS_FOR_PLAN';
export const GET_API_KEYS_FOR_PLAN_SUCCESS = 'GET_API_KEYS_FOR_PLAN_SUCCESS';
export const GET_API_KEYS_FOR_PLAN_FAIL = 'GET_API_KEYS_FOR_PLAN_FAIL';

export const CREATE_API_KEY = 'CREATE_API_KEY';
export const CREATE_API_KEY_FAIL = 'CREATE_API_KEY_FAIL';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';

export const EDIT_API_KEY = 'EDIT_API_KEY';
export const EDIT_API_KEY_SUCCESS = 'EDIT_API_KEY_SUCCESS';
export const EDIT_API_KEY_FAIL = 'EDIT_API_KEY_FAIL';

export const DELETE_API_KEY = 'DELETE_API_KEY';
export const DELETE_API_KEY_SUCCESS = 'DELETE_API_KEY_SUCCESS';
export const DELETE_API_KEY_FAIL = 'DELETE_API_KEY_FAIL';

export const RESET_API_KEY_STATE = 'RESET_API_KEY_STATE';

export const GET_PLAN_PERIODS_FOR_PLAN = 'GET_PLAN_PERIODS_FOR_PLAN';
export const GET_PLAN_PERIODS_FOR_PLAN_FAIL = 'GET_PLAN_PERIODS_FOR_PLAN_FAIL';
export const GET_PLAN_PERIODS_FOR_PLAN_SUCCESS = 'GET_PLAN_PERIODS_FOR_PLAN_SUCCESS';

export const GET_API_LOGS_FOR_PLAN = 'GET_API_LOGS_FOR_PLAN';
export const GET_API_LOGS_FOR_PLAN_SUCCESS = 'GET_API_LOGS_FOR_PLAN_SUCCESS';
export const GET_API_LOGS_FOR_PLAN_FAIL = 'GET_API_LOGS_FOR_PLAN_FAIL';

export const SET_WHITE_LABEL_HOST = 'SET_WHITE_LABEL_HOST';
export const CLEAR_WHITE_LABEL = 'CLEAR_WHITE_LABEL';

export const GET_WHITE_LABEL_API = 'GET_WHITE_LABEL_API';
export const GET_WHITE_LABEL_API_FAIL = 'GET_WHITE_LABEL_API_FAIL';
export const GET_WHITE_LABEL_API_SUCCESS = 'GET_WHITE_LABEL_API_SUCCESS';

export const GET_PLANS_FOR_ORGANIZATION = 'GET_PLANS_FOR_ORGANIZATION';
export const GET_PLANS_FOR_ORGANIZATION_FAIL = 'GET_PLANS_FOR_ORGANIZATION_FAIL';
export const GET_PLANS_FOR_ORGANIZATION_SUCCESS = 'GET_PLANS_FOR_ORGANIZATION_SUCCESS';

export const ADMIN_GET_ALL_ORGS = 'ADMIN_GET_ALL_ORGS';
export const ADMIN_GET_ALL_ORGS_FAIL = 'ADMIN_GET_ALL_ORGS_FAIL';
export const ADMIN_GET_ALL_ORGS_SUCCESS = 'ADMIN_GET_ALL_ORGS_SUCCESS';

export const ADMIN_SET_SPY_ORG = 'ADMIN_SET_SPY_ORG';

export const SEND_SUPPORT_EMAIL = 'SEND_SUPPORT_EMAIL';
export const SEND_SUPPORT_EMAIL_SUCCESS = 'SEND_SUPPORT_EMAIL_SUCCESS';
export const SEND_SUPPORT_EMAIL_FAIL = 'SEND_SUPPORT_EMAIL_FAIL';

export const SET_DETAIL_ADMIN_API = 'SET_DETAIL_ADMIN_API';

export const CREATE_API = 'CREATE_API';
export const API_CREATE_FAIL = 'API_CREATE_FAIL';
export const API_CREATE_SUCCESS = 'API_CREATE_SUCCESS';

export const UPDATE_API = 'UPDATE_API';
export const API_UPDATE_FAIL = 'API_UPDATE_FAIL';
export const API_UPDATE_SUCCESS = 'API_UPDATE_SUCCESS';

export const DELETE_API = 'DELETE_API';
export const API_DELETE_FAIL = 'API_DELETE_FAIL';
export const API_DELETE_SUCCESS = 'API_DELETE_SUCCESS';

export const GET_API = 'GET_API';
export const API_GET_FAIL = 'API_GET_FAIL';
export const API_GET_SUCCESS = 'API_GET_SUCCESS';

export const GET_USER_INVOICES = 'GET_USER_INVOICES';
export const GET_USER_INVOICES_FAIL = 'GET_USER_INVOICES_FAIL';
export const GET_USER_INVOICES_SUCCESS = 'GET_USER_INVOICES_SUCCESS';

export const GET_USAGE_INFORMATION_FOR_ORGANIZATION = 'GET_USAGE_INFORMATION_FOR_ORGANIZATION';
export const GET_USAGE_INFORMATION_FOR_ORGANIZATION_FAIL = 'GET_USAGE_INFORMATION_FOR_ORGANIZATION_FAIL';
export const GET_USAGE_INFORMATION_FOR_ORGANIZATION_SUCCESS = 'GET_USAGE_INFORMATION_FOR_ORGANIZATION_SUCCESS';

export const GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION = 'GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION';
export const GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_FAIL = 'GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_FAIL';
export const GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_SUCCESS = 'GET_COUNT_OF_CALLS_IN_CURRENT_PERIOD_FOR_ORGANIZATION_SUCCESS';

export const DELETE_USER = 'DELETE_USER'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'

export const DELETE_USER_BY_ORG_ID = 'DELETE_USER_BY_ORG_ID'
export const DELETE_USER_BY_ORG_ID_FAIL = 'DELETE_USER_BY_ORG_ID_FAIL'
export const DELETE_USER_BY_ORG_ID_SUCCESS = 'DELETE_USER_BY_ORG_ID_SUCCESS'

export const CLEAR_STRIPE_ERROR = 'CLEAR_STRIPE_ERROR'

export const SHOW_STRIPE_PAYMENT_MODAL = 'SHOW_STRIPE_PAYMENT_MODAL'
export const SHOW_STRIPE_FREE_PAYMENT_MODAL = 'SHOW_STRIPE_FREE_PAYMENT_MODAL'
export const REMOVE_STRIPE_PAYMENT_MODAL = 'REMOVE_STRIPE_PAYMENT_MODAL'
export const TOGGLE_STRIPE_PAYMENT_MODAL = 'TOGGLE_STRIPE_PAYMENT_MODAL'

export const GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS = 'GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS'
export const GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_FAIL = 'GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_FAIL'
export const GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_SUCCESS = 'GET_LATEST_STRIPE_SUBSCRIPTION_DETAILS_SUCCESS'

export const START_INTERCOM_CONVERSATION_SUCCESS = 'START_INTERCOM_CONVERSATION_SUCCESS'

export const GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION = 'GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION'
export const GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_FAIL = 'GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_FAIL'
export const GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_SUCCESS = 'GET_TOTAL_API_CREDITS_USED_FOR_ORGANIZATION_SUCCESS'

export const GET_LATEST_PLAN_DETAILS = 'GET_LATEST_PLAN_DETAILS'
export const GET_LATEST_PLAN_DETAILS_FAIL = 'GET_LATEST_PLAN_DETAILS_FAIL'
export const GET_LATEST_PLAN_DETAILS_SUCCESS = 'GET_LATEST_PLAN_DETAILS_SUCCESS'

export const GET_ORGANIZATION_USERS = 'GET_ORGANIZATION_USERS'
export const GET_ORGANIZATION_USERS_FAIL = 'GET_ORGANIZATION_USERS_FAIL'
export const GET_ORGANIZATION_USERS_SUCCESS = 'GET_ORGANIZATION_USERS_SUCCESS'

export const GET_USER_ORGANIZATIONS = 'GET_USER_ORGANIZATIONS'
export const GET_USER_ORGANIZATIONS_FAIL = 'GET_USER_ORGANIZATIONS_FAIL'
export const GET_USER_ORGANIZATIONS_SUCCESS = 'GET_USER_ORGANIZATIONS_SUCCESS'

export const SET_CURRENT_ORG = 'SET_CURRENT_ORG'

export const INVITE_USER_TO_ORGANIZATION = 'INVITE_USER_TO_ORGANIZATION'
export const INVITE_USER_TO_ORGANIZATION_FAIL = 'INVITE_USER_TO_ORGANIZATION_FAIL'
export const INVITE_USER_TO_ORGANIZATION_SUCCESS = 'INVITE_USER_TO_ORGANIZATION_SUCCESS'

export const REMOVE_USER_FROM_ORGANIZATION = 'REMOVE_USER_FROM_ORGANIZATION'
export const REMOVE_USER_FROM_ORGANIZATION_FAIL = 'REMOVE_USER_FROM_ORGANIZATION_FAIL'
export const REMOVE_USER_FROM_ORGANIZATION_SUCCESS = 'REMOVE_USER_FROM_ORGANIZATION_SUCCESS'

export const SET_INVITE_VALIDATION_ERROR = 'SET_INVITE_VALIDATION_ERROR'
export const CLEAR_INVITE_VALIDATION_ERROR = 'CLEAR_INVITE_VALIDATION_ERROR'

export const SET_NEW_USER_INVITE_EMAIL = 'SET_NEW_USER_INVITE_EMAIL'
export const SET_NEW_USER_INVITE_ROLE = 'SET_NEW_USER_INVITE_ROLE'

export const TOGGLE_NEW_USER_INVITE_DIALOG = 'TOGGLE_NEW_USER_INVITE_DIALOG'
export const CLEAR_NEW_USER_INVITE_DIALOG = 'CLEAR_NEW_USER_INVITE_DIALOG'

export const CLEAR_ORGANIZATION_ERROR = 'CLEAR_ORGANIZATION_ERROR'
export const CLEAR_INVITE_USER_ERROR = 'CLEAR_INVITE_USER_VALIDATION_ERROR'
export const CLEAR_ORGANIZATION_ALERT = 'CLEAR_ORGANIZATION_ALERT'

export const GET_CURRENT_USER_ORGANIZATION_INVITES = 'GET_CURRENT_USER_ORGANIZATION_INVITES'
export const GET_CURRENT_USER_ORGANIZATION_INVITES_FAIL = 'GET_CURRENT_USER_ORGANIZATION_INVITES_FAIL'
export const GET_CURRENT_USER_ORGANIZATION_INVITES_SUCCESS = 'GET_CURRENT_USER_ORGANIZATION_INVITES_SUCCESS'

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
export const UPDATE_ORGANIZATION_FAIL = 'UPDATE_ORGANIZATION_FAIL'
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS'

export const UPDATE_ORGANIZATION_INVITE = 'UPDATE_ORGANIZATION_INVITE'
export const UPDATE_ORGANIZATION_INVITE_FAIL = 'UPDATE_ORGANIZATION_INVITE_FAIL'
export const UPDATE_ORGANIZATION_INVITE_SUCCESS = 'UPDATE_ORGANIZATION_INVITE_SUCCESS'

export const UPDATE_USER_ROLE_IN_ORGANIZATION = 'UPDATE_USER_ROLE_IN_ORGANIZATION'
export const UPDATE_USER_ROLE_IN_ORGANIZATION_FAIL = 'UPDATE_USER_ROLE_IN_ORGANIZATION_FAIL'
export const UPDATE_USER_ROLE_IN_ORGANIZATION_SUCCESS = 'UPDATE_USER_ROLE_IN_ORGANIZATION_SUCCESS'

export const SET_DEFAULT_ORGANIZATION = 'SET_DEFAULT_ORGANIZATION'
export const SET_DEFAULT_ORGANIZATION_FAIL = 'SET_DEFAULT_ORGANIZATION_FAIL'
export const SET_DEFAULT_ORGANIZATION_SUCCESS = 'SET_DEFAULT_ORGANIZATION_SUCCESS'

export const GET_ORGANIZATION_CURRENT_TIER = 'GET_ORGANIZATION_CURRENT_TIER'
export const GET_ORGANIZATION_CURRENT_TIER_FAIL = 'GET_ORGANIZATION_CURRENT_TIER_FAIL'
export const GET_ORGANIZATION_CURRENT_TIER_SUCCESS = 'GET_ORGANIZATION_CURRENT_TIER_SUCCESS'

export const SET_LAST_CALLED_URL = 'SET_LAST_CALLED_URL'
export const SET_API_TAB_VALUE = 'SET_API_TAB_VALUE'
export const SET_API_RESPONSE_TAB = 'SET_API_RESPONSE_TAB'

export const HANDLE_URL_CHANGE = 'HANDLE_URL_CHANGE'
export const SET_LANGUAGE_VALUE = 'SET_LANGUAGE_VALUE'
export const SET_PROXY = 'SET_PROXY'
export const TOGGLE_FULL_RENDER = 'TOGGLE_FULL_RENDER'
export const TOGGLE_FULL_PAGE = 'TOGGLE_FULL_PAGE'
export const TOGGLE_EMBED_ORIENTATION = 'TOGGLE_EMBED_ORIENTATION';
export const TOGGLE_ALWAYS_OG_FRAME = 'TOGGLE_ALWAYS_OG_FRAME';
export const HANDLE_QUALITY_CHANGE = 'HANDLE_QUALITY_CHANGE'
export const HANDLE_VIEWPORT_WIDTH_CHANGE = 'HANDLE_VIEWPORT_WIDTH_CHANGE'
export const SET_FORCE_CACHE_UPDATE = 'SET_FORCE_CACHE_UPDATE'
export const SET_AUTO_PROXY = 'SET_AUTO_PROXY'
export const SET_HAS_AUTO_PROXY = 'SET_HAS_AUTO_PROXY'
export const SET_SELECTED_ELEMENTS = 'SET_SELECTED_ELEMENTS'
export const SET_DEBUGGER_FORM_ALERT = 'SET_DEBUGGER_FORM_ALERT'

export const MAKE_DEBUGGER_API_CALL = 'MAKE_DEBUGGER_API_CALL'
export const MAKE_DEBUGGER_API_CALL_SUCCESS = 'MAKE_DEBUGGER_API_CALL_SUCCESS'
export const MAKE_DEBUGGER_API_CALL_ERROR = 'MAKE_DEBUGGER_API_CALL_ERROR'

export const GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION = 'GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION'
export const GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION_SUCCESS = 'GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION_SUCCESS'
export const GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION_FAIL = 'GET_ORGANIZATION_CURRENT_CYCLE_INFORMATION_FAIL'

export const GET_ORGANIZATION_STRIPE_SUBSCRIPTION = 'GET_ORGANIZATION_STRIPE_SUBSCRIPTION'
export const GET_ORGANIZATION_STRIPE_SUBSCRIPTION_SUCCESS = 'GET_ORGANIZATION_STRIPE_SUBSCRIPTION_SUCCESS'
export const GET_ORGANIZATION_STRIPE_SUBSCRIPTION_FAIL = 'GET_ORGANIZATION_STRIPE_SUBSCRIPTION_FAIL'

export const GET_ORGANIZATION_STRIPE_INFO = 'GET_ORGANIZATION_STRIPE_INFO'
export const GET_ORGANIZATION_STRIPE_INFO_SUCCESS = 'GET_ORGANIZATION_STRIPE_INFO_SUCCESS'
export const GET_ORGANIZATION_STRIPE_INFO_FAIL = 'GET_ORGANIZATION_STRIPE_INFO_FAIL'
export const BUILD_SNIPPET = 'BUILD_SNIPPET';
export const SCROLL_TO_RESULTS = 'SCROLL_TO_RESULTS';

export const EDIT_LINK_PREVIEW_META_DATA = 'EDIT_LINK_PREVIEW_META_DATA';
export const EDIT_UPDATED_RESULTS = 'EDIT_UPDATED_RESULTS';
export const SET_ACTIVE_LINK_PREVIEW_TAB = 'SET_ACTIVE_LINK_PREVIEW_TAB';

export const MAKE_LINK_PREVIEW_CALL = 'MAKE_LINK_PREVIEW_CALL';
export const MAKE_LINK_PREVIEW_CALL_SUCCESS = 'MAKE_LINK_PREVIEW_CALL_SUCCESS';
export const MAKE_LINK_PREVIEW_CALL_ERROR = 'MAKE_LINK_PREVIEW_CALL_ERROR';

export const TOGGLE_MARKETING_SIGN_UP_MODAL = 'TOGGLE_MARKETING_SIGN_UP_MODAL';
